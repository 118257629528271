import React, { Component } from "react";

export default class Tabs extends Component {
    state = {
        current: null
    };

    componentDidMount() {
        if (this.props.defaultTab) {
            this.setState({
                current: this.props.defaultTab
            });
        } else {
            this.setState({
                current: Object.keys(this.props.tabs)[0]
            });
        }
    }

    render() {
        return (
            <div className="tabs-container">
                <ul className="tabs-navigation">
                    {Object.keys(this.props.tabs).map(key => (
                        <li
                            key={"tab_" + key}
                            className={this.state.current === key ? "active" : ""}
                            onClick={() => this.setState({ current: key }, () => this.props.changeTab(key))}
                        >
                            {this.props.tabs[key].label}
                        </li>
                    ))}
                </ul>
                <div className="clearfix" />
                <div className="tabs-content-container">
                    {this.state.current &&
                    this.props.tabs[this.state.current] &&
                    this.props.tabs[this.state.current].render !== undefined &&
                    this.props.tabs[this.state.current].render
                        ? this.props.tabs[this.state.current].render()
                        : null}
                </div>
            </div>
        );
    }
}
