import React from "react";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import ApiHelper from "helpers/ApiHelper";
import Calendar from "./Calendar";

class CreateMeeting extends React.Component {
    state = {
        isSaving: false,
        type: "personally",
        consultants: [],
        consultantTarget: 0,
        date: moment().weekday() >= 4 ? moment().weekday(7) : moment().add(1, "days"),
        time: "none",
        timesAreLoading: false,
        times: [],
        mail: "",
        phone: "",
        mailIsValid: false,
        submitAllowed: false,
        showCalendar: false
    };

    componentDidMount() {
        this.loadTimes();
        this.loadConsultants();
    }

    loadTimes() {
        this.setState({
            timesAreLoading: true,
            times: [],
            time: "none",
            submitAllowed: false
        });

        ApiHelper.request("/campaign/contact/meeting/times", {
            date: this.state.date.format("YYYY-MM-DD")
        })
            .then(response => {
                this.setState({
                    timesAreLoading: false,
                    times: response.data
                });
            })
            .catch(e => {
                this.setState({
                    timesAreLoading: false,
                    times: []
                });
            });
    }

    loadConsultants(){
        ApiHelper.request("/campaign/contact/meeting/consultants",)
        .then(response => {
            this.setState({
                consultants: response.data,
                consultantTarget: response.data && response.data.length > 0 && response.data[0].id
            });
        })
        .catch(e => {
        });
    }

    validateMail() {
        const mail = this.state.mail;
        let isValid = false;

        if (
            mail.indexOf("@") > 0 &&
            mail.lastIndexOf(".") > mail.length - 6 &&
            mail.lastIndexOf(".") < mail.length - 2 &&
            mail.lastIndexOf(".") > mail.indexOf("@")
        ) {
            isValid = true;
        }

        this.setState({ mailIsValid: isValid }, () => {
            this.checkSubmitAllowed();
        });
    }

    checkSubmitAllowed() {
        let submitAllowed = false;

        if (this.state.type !== "none") {
            if (this.state.type === "email") {
                if (this.state.mailIsValid) {
                    submitAllowed = true;
                }
            } else {
                if (this.state.date && this.state.time !== "none") {
                    if (this.state.type === "phone") {
                        if (this.state.phone.length >= 11) {
                            submitAllowed = true;
                        }
                    } else {
                        submitAllowed = true;
                    }
                }
            }
        }

        this.setState({ submitAllowed: submitAllowed });
    }

    save() {
        this.setState({
            isSaving: true
        });

        ApiHelper.request("/campaign/contact/meeting/create", {
            contact_id: this.props.contact.id,
            campaign_id: this.props.campaign.id,
            type: this.state.type,
            date: this.state.type !== "email" ? this.state.date.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            time: this.state.type !== "email" ? this.state.time : "",
            consultant: this.state.consultantTarget
            //phone: this.state.phone,
            //mail: this.state.mail
        }).then(response => {
            this.props.alert("Termin erfolgreich angelegt");
            this.props.closeModal();
        });
    }

    render() {
        let { contact, campaign } = this.props;

        const datePicker =
            this.state.type !== "none" && this.state.type !== "email" ? (
                <React.Fragment>
                    {this.state.type === "phone" ? (
                        <div className="form-group">
                            <label>Telefonnr.:</label>
                            <input
                                className="form-control"
                                type="tel"
                                value={this.state.phone}
                                onChange={event =>
                                    this.setState({ phone: event.target.value }, () => {
                                        this.checkSubmitAllowed();
                                    })
                                }
                            />
                        </div>
                    ) : null}
                    <div className="form-group">
                        <label>Datum:</label>
                        <div style={{ textAlign: "center" }}>
                            <DatePicker
                                inline
                                selected={this.state.date.toDate()}
                                locale="de"
                                minDate={new Date()}
                                onChange={dateString => {
                                    this.setState({ date: moment(dateString), time: null }, () => {
                                        this.loadTimes();
                                    });
                                }}
                                utcOffset={+2}
                                filterDate={data => {
                                    if (moment(data).weekday() >= 5) {
                                        // Samstag (5) und Sonntag (6)
                                        return false;
                                    }
                                    return true;
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-group" style={{ position: "relative" }}>
                        <button
                            className="btn btn-info"
                            style={{
                                padding: "2px 5px",
                                fontSize: "11px",
                                position: "absolute",
                                margin: "0px",
                                marginTop: "-2px",
                                right: "0px"
                            }}
                            onClick={() => this.setState({ showCalendar: true })}
                        >
                            Kalender anzeigen
                        </button>
                        <label>Uhrzeit:</label>
                        <div style={{ overflow: "auto" }}>
                            <div className="time-element">
                                {this.state.timesAreLoading ? (
                                    <div>
                                        <i className="zmdi zmdi-spinner rotating" style={{ marginRight: "5px" }} />
                                        Verfügbare Uhrzeiten werden geladen ...
                                    </div>
                                ) : this.state.times && this.state.times.length > 0 ? (
                                    <div>
                                        <select
                                            className="custom-select"
                                            onChange={e =>
                                                this.setState({ time: e.target.value }, () => {
                                                    this.checkSubmitAllowed();
                                                })
                                            }
                                            value={this.state.time ? this.state.time : ""}
                                        >
                                            <option value="none">Uhrzeit wählen</option>
                                            {this.state.times.map(time => (
                                                <option key={"time_" + time} value={time}>
                                                    {time}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : (
                                    <div>Keine verfügbaren Termine am {this.state.date.format("DD.MM.YYYY")}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : this.state.type === "email" ? (
                <React.Fragment>
                    <div className="form-group">
                        <label>E-Mail:</label>
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={this.state.mail}
                            onChange={event =>
                                this.setState({ mail: event.target.value }, () => {
                                    this.validateMail();
                                })
                            }
                        />
                    </div>
                </React.Fragment>
            ) : null;

        let content;
        if (this.state.isSaving) {
            content = (
                <div style={{ textAlign: "center" }}>
                    <i className="zmdi zmdi-spinner rotating" style={{ marginBottom: "5px", fontSize: "26px" }} />
                    <br />
                    Termin wird gespeichert ...
                </div>
            );
        } else {
            content = (
                <React.Fragment>
                    <div className="card">
                        <div className="card-body" style={{ fontSize: "0.9rem", padding: "18px" }}>
                            {contact.lastname}, {contact.firstname}
                            <br />
                            {contact.address.street} {contact.address.housenumber}
                            <br />
                            {contact.address.zip} {contact.address.city}
                            <div style={{ fontSize: "0.75rem" }}>Kampagne: {campaign.name}</div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Termin Typ:</label>
                        <select
                            className="custom-select"
                            onChange={e =>
                                this.setState({ type: e.target.value }, () => {
                                    this.checkSubmitAllowed();
                                })
                            }
                            value={this.state.type}
                        >
                            <option value="none">Kategorie wählen</option>
                            <option value="personally">Persönlich</option>
                            <option value="phone">Telefonisch</option>
                            <option value="email">E-Mail</option>
                        </select>
                    </div>

                    {this.state.consultants.length > 0 &&
                        <div className="form-group">
                            <label>Termin erstellen für:</label>
                            <select
                                className="custom-select"
                                onChange={e =>
                                    this.setState({consultantTarget: e.target.value}, () => {
                                        this.checkSubmitAllowed();
                                    })
                                }
                                value={this.state.consultantTarget}
                            >
                                {this.state.consultants.map( consultant => {
                                    return <option key={consultant.id} value={consultant.id} >{consultant.name}</option>
                                })}
                            </select>
                        </div>
                    }
                    {datePicker}

                    <button
                        className="btn btn-success btn-block btn-lg"
                        style={{ textTransform: "none", height: "100%" }}
                        onClick={this.save.bind(this)}
                        disabled={!this.state.submitAllowed}
                    >
                        <i className="far fa-save" />
                        <br />
                        Termin erstellen
                    </button>
                </React.Fragment>
            );
        }

        let calendar;
        if (this.state.showCalendar) {
            calendar = <Calendar close={() => this.setState({ showCalendar: false })} date={this.state.date} />;
        }

        return (
            <React.Fragment>
                <h1>Termin vereinbaren</h1>

                {content}
                {calendar}
            </React.Fragment>
        );
    }
}

export default withRouter(CreateMeeting);
