import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Modal from "react-modal";
import CreateMeeting from "./CreateMeeting/CreateMeeting";
import NoInterest from "./NoInterest/NoInterest";
import NotMet from "./NotMet/NotMet";
import Success from "./Success/Success";

class Actions extends Component {
    state = {
        moreOptions: false,
        selectedOption: null
    };

    closeModal(reload) {
        this.setState({
            selectedOption: null
        });

        if (reload) {
            this.props.load(this.props.campaign.id, this.props.contact.id);
        }
    }

    render() {
        let { contact, campaign, successes } = this.props;

        let modalContent;
        if (this.state.selectedOption === "meeting") {
            modalContent = (
                <CreateMeeting
                    contact={contact}
                    campaign={campaign}
                    successes={successes}
                    alert={this.props.alert}
                    closeModal={this.closeModal.bind(this)}
                />
            );
        } else if (this.state.selectedOption === "no-interest") {
            modalContent = (
                <NoInterest
                    contact={contact}
                    campaign={campaign}
                    successes={successes}
                    alert={this.props.alert}
                    closeModal={this.closeModal.bind(this)}
                />
            );
        } else if (this.state.selectedOption === "not-met") {
            modalContent = (
                <NotMet
                    contact={contact}
                    campaign={campaign}
                    successes={successes}
                    alert={this.props.alert}
                    closeModal={this.closeModal.bind(this)}
                />
            );
        } else if (this.state.selectedOption === "success") {
            modalContent = (
                <Success
                    contact={contact}
                    campaign={campaign}
                    successes={successes}
                    alert={this.props.alert}
                    closeModal={this.closeModal.bind(this)}
                />
            );
        }

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.state.selectedOption !== null ? true : false}
                    style={{
                        content: {
                            top: "85px",
                            left: "15px",
                            right: "15px",
                            bottom: "15px"
                        }
                    }}
                >
                    <div>
                        <div className="float-right">
                            <i
                                className="zmdi zmdi-close zmdi-hc-fw"
                                style={{ fontSize: 28, cursor: "pointer" }}
                                onClick={() => this.setState({ selectedOption: null })}
                            />
                        </div>
                        {modalContent}
                    </div>
                </Modal>

                <div className="row" style={{ paddingTop: "15px" }}>
                    <div className="col-12">
                        <button
                            onClick={() => this.setState({ selectedOption: "meeting" })}
                            className="btn btn-success btn-block btn-lg"
                            style={{
                                textTransform: "none",
                                whiteSpace: "normal",
                                height: "100%"
                            }}
                        >
                            <i className="far fa-calendar-alt" />
                            <br />
                            Termin vereinbaren
                        </button>
                    </div>

                    <div className="col-6" style={{ paddingTop: "15px" }}>
                        <button
                            onClick={() => this.setState({ selectedOption: "not-met" })}
                            className="btn btn-warning btn-block btn-lg"
                            style={{
                                textTransform: "none",
                                fontSize: "14px",
                                color: "#fff",
                                whiteSpace: "normal",
                                height: "100%"
                            }}
                        >
                            <i className="fas fa-question" />
                            <br />
                            Nicht angetroffen
                        </button>
                    </div>
                    <div className="col-6" style={{ paddingTop: "15px" }}>
                        <button
                            onClick={() => this.setState({ selectedOption: "no-interest" })}
                            className="btn btn-danger btn-block btn-lg"
                            style={{
                                textTransform: "none",
                                fontSize: "14px",
                                whiteSpace: "normal",
                                height: "100%"
                            }}
                        >
                            <i className="fas fa-times" />
                            <br />
                            Kein Interesse
                        </button>
                    </div>
                    <div className="col-12" style={{ paddingTop: "15px" }}>
                        <button
                            onClick={() => this.setState({ selectedOption: "success" })}
                            className="btn btn-success btn-block btn-lg"
                            style={{
                                textTransform: "none",
                                fontSize: "14px",
                                whiteSpace: "normal",
                                height: "100%"
                            }}
                        >
                            <i className="fas fa-clipboard-check" />
                            <br />
                            Erfolg hinterlegen
                        </button>
                    </div>

                    <div className="col-12">
                        <button
                            className="btn btn-blue-grey btn-block btn-lg"
                            onClick={() =>
                                this.setState({
                                    moreOptions: !this.state.moreOptions
                                })
                            }
                            style={{
                                marginTop: "15px",
                                marginBottom: "15px",
                                padding: "10px 0px",
                                display: "block",
                                fontSize: "14px"
                            }}
                        >
                            {!this.state.moreOptions ? "Mehr Optionen" : "Weniger Optionen"}
                            <i
                                style={{ marginLeft: "8px" }}
                                className={"fas fa-angle-" + (!this.state.moreOptions ? "down" : "up")}
                            />
                        </button>
                    </div>

                    {this.state.moreOptions ? (
                        <React.Fragment>
                            <div className="col-12" style={{ paddingBottom: "15px" }}>
                                <a
                                    rel="noopener noreferrer"
                                    href={
                                        "https://www.google.de/maps/dir//" +
                                        contact.address.street +
                                        "+" +
                                        contact.address.housenumber +
                                        ",+" +
                                        contact.address.zip +
                                        "+" +
                                        contact.address.city
                                    }
                                    target="_blank"
                                    className="btn btn-blue btn-block btn-lg"
                                    style={{
                                        textTransform: "none",
                                        fontSize: "12px",
                                        whiteSpace: "normal",
                                        height: "100%"
                                    }}
                                >
                                    <i className="fas fa-map-marked-alt" />
                                    <br />
                                    Route finden
                                </a>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn btn-warning btn-block btn-lg"
                                    style={{
                                        textTransform: "none",
                                        fontSize: "14px",
                                        whiteSpace: "normal",
                                        height: "100%"
                                    }}
                                    disabled={true}
                                >
                                    <i className="fas fa-file-signature" />
                                    <br />
                                    Lead erzeugen
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className="btn btn-danger btn-block btn-lg"
                                    style={{
                                        textTransform: "none",
                                        fontSize: "14px",
                                        whiteSpace: "normal",
                                        height: "100%"
                                    }}
                                    disabled={true}
                                >
                                    <i className="fas fa-user-slash" />
                                    <br />
                                    Kontakt sperren
                                </button>
                            </div>
                        </React.Fragment>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}
export default withRouter(Actions);
