export const SET_AUTH      = "SET_LOGIN";
export const OPEN_SIDEBAR  = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const OPEN_SEARCH   = "OPEN_SEARCH";
export const CLOSE_SEARCH  = "CLOSE_SEARCH";
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const SEND_SEARCH   = "SEND_SEARCH";
export const LOGOUT        = "LOGOUT";
export const SET_BACKLINK  = "SET_BACKLINK";
export const SET_RELOGIN   = "SET_RELOGIN";

export function setAuth(token, user) {
    localStorage.setItem('api_token', token);
    localStorage.setItem('user', JSON.stringify(user));
    
    return { type: SET_AUTH, payload: { token: token, user: user } };
}

export function openSidebar() {
    return { type: OPEN_SIDEBAR };
}

export function closeSidebar() {
    return { type: CLOSE_SIDEBAR };
}

export function openSearch() {
    return { type: OPEN_SEARCH };
}

export function closeSearch() {
    return { type: CLOSE_SEARCH };
}

export function updateSearch(searchInput) {
    return { type: UPDATE_SEARCH, payload: { searchInput: searchInput } };
}

export function requestSearch(sendSearch) {
    return { type: SEND_SEARCH, payload: { sendSearch: sendSearch } };
}

export function setBacklink(backlink) {
    return { type: SET_BACKLINK, payload: { backlink: backlink } };
}

export function setRelogin(relogin) {
    return { type: SET_RELOGIN, payload: { relogin: relogin } };
}

export function logout() {
    localStorage.removeItem('user')
    return { type: LOGOUT };
}