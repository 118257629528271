import React from 'react';

const SearchBox = ({styleName, placeholder, onChange, submitSearch, value}) => {

    return (
        <div className={`search-bar left-side-icon bg-transparent ${styleName}`} style={{fontSize: "1.35rem"}}>
            <div className="form-group">
                <input name="searchInput" className="form-control border-0" type="search" placeholder={placeholder} onChange={onChange}
                       value={value} style={{fontSize: "1.35rem"}}/>
                <button className="search-icon" onClick={submitSearch}><i className="zmdi zmdi-search zmdi-hc-2x" style={{verticalAlign: "middle", fontSize: "1.35rem"}}/></button>
            </div>
        </div>
    )
};
export default SearchBox;

SearchBox.defaultProps = {
    styleName: "",
    value: "",
    placeholder: "Search here..",
    submitSearch: () => {}
};