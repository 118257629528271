import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//import ApiHelper from "helpers/ApiHelper";
import "react-table/react-table.css";
import moment from 'moment';
import { requestSearch } from "redux/actions";

class SearchResult extends Component {
    state = {
        data: null,
        taleData: null,
        isLoading: true,
        oldSearch: ""
    };

    componentDidMount()
    {
        this.timerID = setInterval(
            () => this.tick(),
            500
        );
    }

    componentWillUnmount()
    {
        clearInterval(this.timerID);
    }

    tick()
    {
        if(this.props.sendSearch)
        {
            this.props.requestSearch(false);

            this.setState({
                isLoading: true,
                oldSearch: this.props.searchInput
            });



            // API request

            this.setState({
                isLoading: false
            });
        }

    }

    render() {
        const {tableData} = this.state;
        return (
            <div>
                <h2>Suchergebnis für:</h2>
                <h2>{ this.state.oldSearch }</h2>
                <div className="jr-entry-header mt-4">
                    <div className="entry-heading heading">
                        Gefilterte Kampagnen
                    </div>
                </div>
                <div className="jr-card p-0 border-0">
                    <div className="jr-card-body">
                        <ReactTable
                            loading={ this.state.isLoading }
                            getTdProps={(state, rowInfo) => {
                                return {
                                    onClick: (e, handleOriginal) =>
                                    {
                                        if(rowInfo)
                                        {
                                            this.props.history.push("/campaign/" + rowInfo.original.id + "/details");
                                        }
                                    }
                                }
                            }}
                            data={tableData}
                            columns={[
                                {
                                    Header: "Kampagne",
                                    accessor: "name"
                                },
                                {
                                    id: "begin",
                                    Header: "Beginn",
                                    accessor: d => {
                                        return moment(d).format("DD.MM.YY HH:mm")
                                    }
                                },
                                {
                                    id: "end",
                                    Header: "Ende",
                                    accessor: d => {
                                        return moment(d).format("DD.MM.YY HH:mm")
                                    }
                                },
                            ]}
                            defaultSorted={[
                                {
                                    id: "age",
                                    desc: true
                                }
                            ]}
                            defaultPageSize={10}
                            pageSizeOptions={[10, 15, 20, 25, 35, 50, 100]}
                            className="-striped -highlight"
                            previousText="Vorherige"
                            nextText="Nächste"
                            loadingText="Lade..."
                            noDataText="Keine Kampagnen gefunden"
                            pageText="Seite"
                            ofText="von"
                            rowsText="Kampagnen"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    searchInput: state.app.searchInput,
    sendSearch: state.app.sendSearch
});

const mapDispatch = dispatch => ({
    requestSearch: (sendSearch) => dispatch(requestSearch(sendSearch))
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatch
    )(SearchResult));