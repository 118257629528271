import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class HeaderToggleNav extends React.Component {
    render() {
        let { openNavRequest, openSearchRequest, history, staticContext, dispatch, ...rest } = this.props;

        return (
            <div className="d-flex app-toolbar align-items-center app-header-toggle-nav" {...rest}>
                { this.props.backlink ? (
                    <span
                        className={`jr-menu-icon pointer`}
                        onClick={() => { history.push(this.props.backlink) }}
                    >
                        <span className={'zmdi zmdi-long-arrow-left zmdi-hc-2x pointer'} />
                    </span>
                ) : null }

                <span
                    className={`jr-menu-icon pointer`}
                    onClick={openNavRequest}
                    style={{
                        position: "absolute",
                        right: "10px"
                    }}
                >
                    <span className="menu-icon" />
                </span>

                {/*<span
                    className={`jr-menu-icon pointer`}
                    style={{
                        position: "absolute",
                        right: "15px"
                    }}
                    onClick={openSearchRequest}
                >
                    <span className="zmdi zmdi-search zmdi-hc-2x" />
                </span>*/}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    backlink: state.app.backlink
});

export default withRouter(
    connect(
        mapStateToProps
    )(HeaderToggleNav)
);
