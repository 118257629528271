import React, { Component } from "react";

export class List extends Component {
    render() {
        return (
            <div className="list-group" {...this.props}>
                {this.props.children}
            </div>
        );
    }
}

export class ListEntry extends Component {
    render() {
        return (
            <div className="ripple-effect-click list-group-item-action list-group-item" {...this.props}>
                {this.props.children}
            </div>
        );
    }
}
