import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ApiHelper from "helpers/ApiHelper";

class NoInterest extends Component {
    state = {
        reason: "none",
        interests: [],
        note: ""
    };

    reasons = {
        ki: {
            label: "Kein Interesse",
            description: "pauschale ablehnung ohne weitere Angabe von Gründen.",
            is_potential: true
        },
        ki_abgesagt: {
            label: "Termin abgesagt",
            description: "vereinbarter Termin wurde telefonisch/persönlich abgesagt.",
            is_potential: true
        },
        ki_arbeitslos: {
            label: "Arbeitslos",
            description: "Kunde gibt an, kein Interesse zu haben, da Stromkosten vom Arbeitsamt übernommen werden.",
            is_potential: true
        },
        // ki_bereits_kontaktiert: {
        //     label: "Bereits kontaktiert",
        //     description: "Kunde wurde bereits von anderen Stadtwerkemitarbeitern besucht."
        // },
        ki_blacklist: {
            label: "Blacklist",
            description: "Kunde wurde von den Stadtwerken nachträglich als Kunde mit Besuchsverbot gemeldet.",
            is_potential: false
        },
        ki_geb: {
            label: "Gebunden",
            description: "Kunde gibt an, langfristig gebunden zu sein.",
            is_potential: true
        },
        ki_gerade_gewechselt: {
            label: "Gerade gewechselt",
            description: "Kunde gibt an, gerade gewechselt zu haben.",
            is_potential: true
        },
        ki_guenstiger: {
            label: "Günstiger",
            description: "bei Termin stellt sich heraus, das Kunde de facto günstiger versorgt ist.",
            is_potential: true
        },
        ki_kein_deutsch: {
            label: "Kein Deutsch (Sprachbarriere)",
            description: "Sprachbarriere.",
            is_potential: false
        },
        ki_kein_haustuer: {
            label: "Kein Haustür",
            description: "Kunde wünscht keinen Hausbesuch.",
            is_potential: false
        },
        ki_ma: {
            label: "Mitarbeitertarif",
            description: "Kunde gibt an, mit einem Mitarbeitertarif von konkurrirednem EVU versorgt zu sein.",
            is_potential: false
        },
        // ki_nicht_gefunden: {
        //     label: "Nicht gefunden",
        //     description: "Wohnung / Haus des Kunden wurde nicht gefunden."
        // },
        ki_obsolet: {
            label: "Obsolet",
            description: "Kundendatensatz ist obsolet.",
            is_potential: false
        },
        ki_oeko: {
            label: "Öko",
            description: "Kunde gibt an, dass ihm Ökostrom wichtiger ist als ein günstiger Preis.",
            is_potential: true
        },
        ki_online: {
            label: "Online",
            description: "Kunde gibt an, regelmäßig über Internetportale nach den günstigsten Anbietern - vor allem mit wechselbonus - zu suchen.",
            is_potential: true
        },
        ki_post: {
            label: "Informationen per Post",
            description: "Kunde möchte Informationen postalisch.",
            is_potential: true
        },
        ki_stadtwerke_nachtspeicher: {
            label: "Stadtwerke Kunde mit Nachtspeicher",
            description: "Kunde hat Nachtspeichertarif.",
            is_potential: false
        },
        ki_stadtwerke_kunde: {
            label: "STW Kunde",
            description: "",
            is_potential: false
        },
        ki_gewerbe: {
            label: "Gewerbe",
            description: "",
            is_potential: false
        },
        ki_seniorenwohnung: {
            label: "Seniorenwohnung",
            description: "Es handelt sich um eine Seniorenwohnung.",
            is_potential: false
        },
        // ki_trauerfall: {
        //     label: "Trauerfall",
        //     description: "Kunde ist wegen Trauerfall nicth wirklich ansprechbar."
        // },
        ki_umzug: {
            label: "Umzug",
            description: "Kunde zieht um.",
            is_potential: false
        },
        ki_unzufrieden_mit_stwbo: {
            label: "Unzufrieden mit STWBO",
            description: "Kunde ist unzufrieden mit den Stadtwerken Bochum.",
            is_potential: false
        },
        ki_verwandte: {
            label: "Verwandte",
            description: "Verwandte des Kunden regeln die Vertragssituationen.",
            is_potential: false
        },
        ki_vermieter: {
            label: "Vermieter",
            description: "",
            is_potential: false
        },
        ki_verstorben: {
            label: "Verstorben",
            description: "",
            is_potential: false
        },
        ki_verzogen: {
            label: "Verzogen",
            description: "Kunde wohnt nicht mehr an besagter Adresse.",
            is_potential: false
        },
        ki_zu_alt: {
            label: "Zu alt",
            description: "Kunde ist zu alt, um den Anfrage intellektuell folgen zu können.",
            is_potential: false
        },
        reset_to_has_interest: {
            label: "Interesse zurücksetzen",
            description: "Interesse des Kunden soll zurückgesetzet werden.",
            is_potential: true
        }
    };

    changeInterest(interest, value) {
        let interests = this.state.interests;
        if (value === "none") {
            // @ToDo Aus array entfernen
            for (let i in interests) {
                if (interests[i].sparte === interest.sparte) {
                    interests.splice(i, 1);
                }
            }
        } else {
            let temp = {
                sparte: interest.sparte,
                reason: value,
                value: this.reasons[value].is_potential
            };

            let add = true;
            for (let i in interests) {
                if (interests[i].sparte === interest.sparte) {
                    add = false;
                    interests[i] = temp;
                }
            }

            if (add) {
                interests.push(temp);
            }
        }

        this.setState({
            interests: interests
        });
    }

    save() {
        this.setState({
            isSaving: true
        });

        ApiHelper.request("/campaign/contact/no-interest", {
            contact_id: this.props.contact.id,
            campaign_id: this.props.campaign.id,
            note: this.state.note,
            interests: this.state.interests
        }).then(response => {
            this.props.alert("Kein Interesse erfolgreich gespeichert.");
            this.props.closeModal(true);
        });
    }

    render() {
        let { contact } = this.props;

        return (
            <React.Fragment>
                <h1>Kein Interesse</h1>
                <div className="form-group">
                    <label>Sparten:</label>
                    <table className="table" style={{ margin: "0px" }}>
                        <tbody>
                            {contact && contact.interests && contact.interests.length > 0
                                ? contact.interests.map(interest => {
                                      let value = "none";
                                      for (let i in this.state.interests) {
                                          if (this.state.interests[i].sparte === interest.sparte) {
                                              value = this.state.interests[i].reason;
                                          }
                                      }

                                      return (
                                          <tr key={"contact_interest_" + interest.id}>
                                              <td>
                                                  {interest.sparte_name ? interest.sparte_name : "Unbekannte Sparte"}

                                                  <div>
                                                      {interest.is_current ? (
                                                          <span className="badge badge-success">Hat Interesse</span>
                                                      ) : (
                                                          <span className="badge badge-danger">Kein Interesse</span>
                                                      )}
                                                      <div>
                                                          <small>{interest.serialnumber}</small>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td>
                                                  <select
                                                      className="custom-select"
                                                      value={value}
                                                      onChange={e => {
                                                          this.changeInterest(interest, e.target.value);
                                                      }}
                                                  >
                                                      <option value="none">Nicht verändern</option>
                                                      <option disabled>---------</option>
                                                      {Object.keys(this.reasons).map(key => (
                                                          <option key={"ki_option_" + key} value={key}>
                                                              {this.reasons[key].label}
                                                          </option>
                                                      ))}
                                                  </select>

                                                  {value && this.reasons[value] !== undefined && this.reasons[value] ? (
                                                      <div style={{ fontSize: "10px" }}>
                                                          <b>Beschreibung:</b> {this.reasons[value].description}
                                                      </div>
                                                  ) : null}
                                              </td>
                                          </tr>
                                      );
                                  })
                                : null}
                        </tbody>
                    </table>
                </div>

                <div className="form-group">
                    <label>Bemerkung (optional):</label>
                    <textarea
                        className="form-control"
                        style={{
                            border: "dash",
                            boxShadow: "none",
                            background: "transparent",
                            padding: "10px",
                            marginBottom: "15px"
                        }}
                        rows="4"
                        placeholder="Bemerkung / Notiz / Begründung ..."
                        value={this.state.note}
                        onChange={e => this.setState({ note: e.target.value })}
                    />
                </div>

                <button
                    className="btn btn-danger btn-block btn-lg"
                    style={{ textTransform: "none", height: "100%" }}
                    onClick={this.save.bind(this)}
                    disabled={!this.state.interests || this.state.interests.length < 1}
                >
                    <i className="far fa-save" />
                    <br />
                    "Kein Interesse" speichern
                </button>
            </React.Fragment>
        );
    }
}

export default withRouter(NoInterest);
