import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ApiHelper from "helpers/ApiHelper";

class NotMet extends Component {
    state = {
        note: "",
        reason: "none"
    };

    save() {
        this.setState({
            isSaving: true
        });

        ApiHelper.request("/campaign/contact/not-met", {
            contact_id: this.props.contact.id,
            campaign_id: this.props.campaign.id,
            reason: this.state.reason
        }).then(response => {
            this.props.alert("Nicht angetroffen gespeichert.");
            this.props.closeModal();
        });
    }

    render() {
        return (
            <React.Fragment>
                <h1>Nicht angetroffen</h1>

                <div style={{ marginBottom: '15px' }}>
                    Der Kontakt wird für die nächsten 3 Tage aus der Liste der offenen
                    Kontakte in die "Blockiert"-Liste verschoben.
                </div>

                <div className="form-group">
                    <label>Termin Typ:</label>
                    <select
                        className="custom-select"
                        onChange={e => this.setState({ reason: e.target.value })}
                        value={this.state.type}
                    >
                        <option value="none">Grund wählen</option>
                        <option value="na_leftcard">Karte hinterlassen</option>
                        <option value="na_notmet">Nicht angetroffen</option>
                        <option value="na_noads">Werbeverweigerer</option>
                    </select>
                </div>

                <button
                    className="btn btn-danger btn-block btn-lg"
                    style={{ textTransform: "none", height: "100%" }}
                    onClick={this.save.bind(this)}
                    disabled={ this.state.reason === "none" }
                >
                    <i className="far fa-save"></i>
                    <br/>
                    "Nicht angetroffen" speichern
                </button>
            </React.Fragment>
        );
    }
}

export default withRouter(NotMet);
