import React from 'react';

export class Header extends React.Component {

    render() {
        return (
            <div className="app-header">
                <div className="app-main-header" style={{ height: 'auto' }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}