import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { closeSidebar, setAuth } from "redux/actions";
import { connect } from "react-redux";

class SideBarMenuItem extends React.Component {
    onClickAction()
    {
        if(this.props.Path === "/login")
        {
            this.props.setApi(null, null);
            window.location.reload();
        }

        this.props.closeSidebar()
    }

    render() {
        const styling = "zmdi zmdi-hc-fw" + (this.props.Path === "/login" ? " zmdi-sign-in" : " zmdi-long-arrow-right");

        return(
            <li className="menu no-arrow" onClick={() => this.onClickAction()}>
                <Link role="button" to={this.props.Path}>
                    <i className={ styling }></i>
                    <span className="nav-text">
                        <span>{ this.props.Name }</span>
                    </span>
                </Link>
            </li>
        )
    }
}

const mapStateToProps = state => ({
    sidebarOpen: state.app.sidebarOpen,
    auth: state.app.auth
});
const mapDispatch = dispatch => ({
    closeSidebar: () => dispatch(closeSidebar()),
    setApi: (token, user) => dispatch(setAuth(token, user))
});
export default withRouter(connect(mapStateToProps, mapDispatch)(SideBarMenuItem));