import React from 'react';

export class SideBarMenu extends React.Component {
    render() {
        return (
            <div className="scrollbar" style={{position: "relative", overflow: "hidden", width: "100%", height: "calc(100vh - 70px)"}}>
                <div style={{position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px", overflow: "scroll", marginRight: "-17px", marginBottom: "-17px"}}>
                    <ul className="nav-menu">
                        <li className="nav-header">
                            <span>{ this.props.Label }</span>
                        </li>
                        {this.props.children}
                    </ul>
                </div>
            </div>
        );
    }
}
