import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import Modal from "react-modal";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import de from 'date-fns/locale/de';
import moment from "moment";
import "moment/locale/de";

import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/configureStore";
import ApiHelper from "./helpers/ApiHelper";
import PrivateRoute from "./components/PrivateRoute";
import Layout from "./components/Layout/Layout";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import CampaignView from "./components/Campaign/View";
import CampaignStreets from "./components/Campaign/Streets";
import ContactCampaignDetails from "./components/Campaign/Contact/Details";
import SearchResult from "./components/SearchResult/SearchResult";

import "react-datepicker/dist/react-datepicker.css";
import "./scss/style.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";


const store = configureStore();
ApiHelper.setStore(store);
moment.locale("de");
Modal.setAppElement("#app-site");
registerLocale('de', de);
setDefaultLocale("de");


const App = () => (
    <Provider store={store}>
        <Router>
            <Layout>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <PrivateRoute exact path="/" component={Dashboard} />
                    <PrivateRoute exact path="/search" component={SearchResult} />
                    <PrivateRoute exact path="/campaign/:campaignId/details" component={CampaignView} />
                    <PrivateRoute exact path="/campaign/:campaignId/streets" component={CampaignStreets} />
                    <PrivateRoute
                        exact
                        path="/campaign/:campaignId/contact/:contactId/details"
                        component={ContactCampaignDetails}
                    />
                </Switch>
            </Layout>
        </Router>
    </Provider>
);
ReactDOM.render(<App />, document.getElementById("app-site"));
serviceWorker.unregister();
