import React, { Component } from "react";
import moment from "moment";
import ApiHelper from "helpers/ApiHelper";

export default class Calendar extends Component {
    state = {
        currentDate: moment(),
        isLoading: false,
        times: []
    };

    componentDidMount() {
        this.setState(
            {
                currentDate: this.props.date
            },
            () => {
                this.load();
            }
        );
    }

    load(date) {
        if (date === undefined || !date) {
            date = this.state.currentDate;
        }

        this.setState({
            isLoading: true,
            times: []
        });

        ApiHelper.request("/campaign/contact/meeting/times-details", {
            date: date.format("YYYY-MM-DD")
        }).then(response => {
            this.setState({
                isLoading: false,
                times: response.data
            });
        });
    }

    render() {
        let times;
        if (this.state.isLoading) {
            times = (
                <div style={{ marginTop: "30px", textAlign: "center", padding: "15px" }}>
                    <i className="zmdi zmdi-spinner zmdi-hc-fw rotating" style={{ fontSize: 28 }} />
                    <br />
                    Daten werden geladen ...
                </div>
            );
        } else if (!this.state.times || this.state.times.length < 1) {
            times = <div className="alert alert-danger">Es ist ein Fehler beim Laden der Zeiten aufgetreten.</div>;
        } else {
            times = this.state.times.map(time => {
                return (
                    <div key={"time_key_" + time.time}>
                        <span style={{ color: time.blocked ? "red" : "green" }}>
                            {time.time}
                            {time.sector ? <span style={{ marginLeft: "3px" }}> - Sektor {time.sector}</span> : null}
                        </span>
                    </div>
                );
            });
        }

        return (
            <div
                style={{
                    position: "fixed",
                    top: "0px",
                    left: "0px",
                    zIndex: "100",
                    background: "rgba(255,255,255,0.9)",
                    width: "100%",
                    height: "100%",
                    overflow: "auto"
                }}
            >
                <div style={{ padding: "30px", paddingTop: "90px" }}>
                    <i
                        className="zmdi zmdi-close zmdi-hc-fw"
                        style={{ fontSize: 28, cursor: "pointer", position: "absolute", right: "15px" }}
                        onClick={() => this.props.close()}
                    />
                    <h1 className="text-center">
                        <span
                            style={{ fontSize: "30px", marginRight: "5px", opacity: "0.6" }}
                            onClick={() =>
                                this.setState({ currentDate: this.state.currentDate.subtract(1, "days") }, this.load())
                            }
                        >
                            {"<"}
                        </span>
                        {this.state.currentDate.format("L")}
                        <span
                            style={{ fontSize: "30px", marginLeft: "5px", opacity: "0.6" }}
                            onClick={() =>
                                this.setState({ currentDate: this.state.currentDate.add(1, "days") }, this.load())
                            }
                        >
                            {">"}
                        </span>
                    </h1>

                    <div style={{ textAlign: "center" }}>{times}</div>
                </div>
            </div>
        );
    }
}
